document.addEventListener('DOMContentLoaded', function () {


  // drop open and hide when click outside
  const links = document.querySelectorAll('.js-dropdown-link');
  const innerElements = document.querySelectorAll('.js-dropdown');

  function handleClick(e) {
    const targetElement = document.getElementById(this.hash.substr(1));
    const isAlreadyActive = this.classList.contains('active');
    links.forEach(link => {
      link.classList.remove('active');
      link.setAttribute('aria-expanded', 'false');
    });
    innerElements.forEach(el => el.classList.remove('open'));
    if (!isAlreadyActive) {
      this.classList.add('active');
      targetElement.classList.add('open');
      this.setAttribute('aria-expanded', 'true');
    }
    e.preventDefault();
  }

  function handleMouseUpTouchStartKeyUp(e) {
    const isInsideDropdown = Array.from(links)
      .concat(Array.from(innerElements))
      .some(el => el.contains(e.target));
    if (!isInsideDropdown || e.keyCode === 27) {
      links.forEach(link => {
        link.classList.remove('active');
        link.setAttribute('aria-expanded', 'false');
      });
      innerElements.forEach(el => el.classList.remove('open'));
    }
  }

  links.forEach(link => link.addEventListener('click', handleClick));
  document.addEventListener('mouseup', handleMouseUpTouchStartKeyUp);
  document.addEventListener('touchstart', handleMouseUpTouchStartKeyUp);
  document.addEventListener('keyup', handleMouseUpTouchStartKeyUp);


  // info section bg position
  if (document.querySelectorAll('.js-footer-columns-items').length) {
    window.addEventListener('resize', function () {
      const heights = Array.from(document.querySelectorAll('.js-footer-columns-items')).map(function (element) {
        return element.offsetHeight;
      });

      const maxHeight = Math.max.apply(null, heights);

      document.querySelector('.js-team').style.paddingBottom = maxHeight / 2 + 'px';
      document.querySelector('.js-footer-columns').style.marginTop = -maxHeight / 2 + 'px';
    });

    window.dispatchEvent(new Event('resize'));
  }


  // toggle
  const toggle = function (toggleLinksSelector, togglePanelsSelector, allowCloseAlreadyOpened = true) {
    const toggleLinks = document.querySelectorAll(toggleLinksSelector);
    const togglePanels = document.querySelectorAll(togglePanelsSelector);

    const handleLinkClick = function (e) {
      const link = this;
      const isAlreadyActive = link.classList.contains('active');

      if (!allowCloseAlreadyOpened && isAlreadyActive) {
        e.preventDefault();
        return;
      }

      toggleLinks.forEach(link => {
        link.classList.remove('active');
        link.setAttribute('aria-expanded', 'false');
      });

      togglePanels.forEach(panel => panel.classList.remove('open'));

      if (!isAlreadyActive) {
        link.classList.add('active');
        link.setAttribute('aria-expanded', 'true');
        const panelId = link.getAttribute('href').substring(1);
        const toggleActivePanel = document.getElementById(panelId);
        toggleActivePanel.classList.add('open');
      }
      e.preventDefault();
    };


    document.addEventListener('click', function (e) {
      if (e.target.matches(toggleLinksSelector) || e.target.closest(toggleLinksSelector)) {
        const link = e.target.matches(toggleLinksSelector) ? e.target : e.target.closest(toggleLinksSelector);
        handleLinkClick.call(link, e);
      }
    });
  };

  toggle('.js-services-tabs-links', '.js-services-tabs-panels', false);
  toggle('.js-services-public-accordion-links', '.js-services-public-accordion-panels', true);
  toggle('.js-services-crisis-accordion-links', '.js-services-crisis-accordion-panels', true);
  toggle('.js-team-tabs-links', '.js-team-tabs-panels', false);


  // google maps
  if (document.querySelectorAll('#js-map').length) {
    var LatLng = { lat: 54.6920143, lng: 25.2697617 };
    var myOptions = {
      center: LatLng,
      zoom: 16,
      scrollwheel: false,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      styles: [
        {
          "featureType": "all",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "gamma": 0.01
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "saturation": -31
            },
            {
              "lightness": -33
            },
            {
              "weight": 2
            },
            {
              "gamma": 0.8
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#050505"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#fef3f3"
            },
            {
              "weight": "3.01"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#0a0a0a"
            },
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#fffbfb"
            },
            {
              "weight": "3.01"
            },
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
            {
              "lightness": 30
            },
            {
              "saturation": 30
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "saturation": 20
            }
          ]
        },
        {
          "featureType": "poi.attraction",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "lightness": 20
            },
            {
              "saturation": -20
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "lightness": 10
            },
            {
              "saturation": -30
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "saturation": 25
            },
            {
              "lightness": 25
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#a1a1a1"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#292929"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#202020"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "hue": "#0006ff"
            },
            {
              "saturation": "-100"
            },
            {
              "lightness": "13"
            },
            {
              "gamma": "0.00"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#686868"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "color": "#8d8d8d"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#353535"
            },
            {
              "lightness": "6"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#ffffff"
            },
            {
              "weight": "3.45"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#d0d0d0"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "lightness": "2"
            },
            {
              "visibility": "on"
            },
            {
              "color": "#999898"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#383838"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#faf8f8"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "lightness": -20
            }
          ]
        }
      ]
    };

    var map = new google.maps.Map(document.getElementById('js-map'), myOptions);
    const marker = new google.maps.Marker({
      position: LatLng,
      map: map,
      icon: 'img/map-pin.png'
    });

    window.addEventListener('resize', function () {
      map.panTo(LatLng);
    });
  }


});